import axios from "axios"

import init from "../utils/init"

init(() => {
  const countyEl = document.querySelector(`.js--location-input-county`)
  const areaEl = document.querySelector(`.js--location-input-area`)
  if (!countyEl || !areaEl) return

  const csrfToken = document.querySelector(`meta[name=csrf-token]`).content

  axios.defaults.headers.common[`X-CSRF-Token`] = csrfToken

  const getChoicesValues = (el, valuesOnly = false) => {
    let values = el.Choices.getValue(valuesOnly)
    values = Array.isArray(values) ? values : [values]
    return values.filter((v) => (valuesOnly ? v : v.value))
  }

  const toggleAreaVisibility = (force) => {
    areaEl.closest(`.form-row`).classList.toggle(`is-hidden`, !force)
  }

  let preselectedWasSet = true
  const areaSelectedEl = document.querySelector(
    `.js--location-input-area-selected`
  )
  const areasPreselected = areaSelectedEl
    ? [areaSelectedEl.value]
    : getChoicesValues(areaEl, true)

  let locationBlank = null
  const areasFetched = {}

  if (
    areaEl.Choices.config.choices.length &&
    !areaEl.Choices.config.choices[0].value
  ) {
    locationBlank = {
      id: ``,
      name: areaEl.Choices.config.choices[0].label,
      selected: areasPreselected.length ? false : true,
      placeholder: true,
    }
  }

  const setAreas = () => {
    if (!getChoicesValues(countyEl, true).length) {
      toggleAreaVisibility(false)
      areaEl.Choices.clearStore()
      return
    }

    fetchAreas().then(() => {
      const areasFetchedValues = Object.values(areasFetched)
      const areasSelected = getChoicesValues(areaEl, true)
      const countiesSelected = getChoicesValues(countyEl, true)

      const choices = []
      Object.entries(areasFetched).forEach(([key, val]) => {
        if (countiesSelected.includes(key)) choices.push(val)
      })

      areaEl.Choices.clearStore()
      toggleAreaVisibility(!!areasFetchedValues.length)

      areaEl.Choices.setChoices(
        choices.length == 1 ? choices[0].choices : choices,
        `id`,
        `name`,
        true
      )

      if (preselectedWasSet) {
        preselectedWasSet = false
        areaEl.Choices.setChoiceByValue(
          locationBlank
            ? [locationBlank, ...areasPreselected]
            : areasPreselected
        )
      } else {
        areaEl.Choices.setChoiceByValue(
          locationBlank ? [locationBlank, ...areasSelected] : areasSelected
        )
      }
    })
  }

  const fetchAreas = () =>
    new Promise((resolve, reject) => {
      const counties = getChoicesValues(countyEl)

      const fetch = () =>
        new Promise((resolve, reject) => {
          const fetchedIds = Object.keys(areasFetched)
          const county = counties.find((c) => !fetchedIds.includes(c.value))
          if (!county) return resolve()

          return axios({
            method: `get`,
            url: `/api/v1/locations.json?county_id=${encodeURIComponent(
              county.value
            )}`,
          })
            .then(function (response) {
              areasFetched[county.value] = {
                id: county.value,
                label: county.label,
                choices: response.data,
              }
              resolve(fetch())
            })
            .catch(function (error) {
              reject(new Error(error))
            })
        })

      return fetch().then(resolve).catch(reject)
    })

  countyEl.addEventListener(`change`, setAreas)
  setAreas()
})
