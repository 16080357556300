import { delegateEventListener } from "@oddcamp/js-utils/src/event"

delegateEventListener(`[data-linkable]`, `click`, (e, target) => {
  if (e.defaultPrevented) return
  if (e.target.closest(`a, button, label, input, select, textarea`)) return

  const url = target.dataset.linkable
  if (e.ctrlKey || e.metaKey) {
    window.open(url, `_blank`)
  } else {
    window.location.href = url
  }
})
