import axios from "axios"

import Choices from "choices.js"
import { debounce } from "lodash"
import { getElements } from "@oddcamp/js-utils/src/selector"

import init from "../utils/init"

let instances = []

const options = {
  removeItems: true,
  removeItemButton: true,
  shouldSort: false, // important so it doesn't break nested options
  searchResultLimit: 20,
  noResultsText: `Inget resultat`,
}

const destroy = () => {
  instances.forEach((inst) => {
    try {
      inst.choices.destroy()
      inst.element.outerHTML = inst.html // because Choices.destroy() doesn't work as it should (doesn't restore element to it's default form)
    } catch (error) {
      //
    }
  })
}

const create = (selector, options) => {
  getElements(selector).forEach((element) => {
    const html = element.outerHTML
    const choices = new Choices(element, options)
    element.Choices = choices
    instances.push({ element, html, choices })

    if (element.dataset.choicesAjax) {
      setupAjaxChoice(element, choices)
    }
  })
}

const setupAjaxChoice = (element, choices) => {
  let request = null
  let prevValue = ``
  let valuesRestored = false
  // const defaultValues = choices.config.choices.map((i) => {
  //   i.selected = false
  //   return i
  // })

  const setNoResultsMsg = (msg) => {
    const el = choices.choiceList.element.querySelector(`.has-no-results`)
    if (el) el.innerHTML = msg
  }

  const restoreValues = (values) => {
    // choices.setChoices(values || defaultValues, `value`, `label`, true)
    valuesRestored = true
  }

  const onSearch = debounce((value) => {
    if (value.length < 3) {
      if (!valuesRestored) restoreValues()
      return
    }
    valuesRestored = false

    const csrfToken = document.querySelector(`meta[name=csrf-token]`).content

    axios.defaults.headers.common[`X-CSRF-Token`] = csrfToken

    axios({
      method: `get`,
      url: `/api/v1/${element.dataset.choicesAjax}.json`, // prettier-ignore
      params: {
        q: encodeURIComponent(value),
      },
    })
      .then(function (response) {
        if (response.data && response.data.length) {
          if (element.multiple) {
            response.data = response.data.filter(
              (i) => !choices.getValue(true).includes(i.id)
            )
          }
          choices.setChoices(response.data, `id`, `name`, true)
        } else {
          choices.setChoices([])
          setNoResultsMsg(`Inget resultat`)
        }
      })
      .then(function () {
        request = null
      })

    // Intercept request
    axios.interceptors.request.use(
      function (config, xhr) {
        if (request) {
          request.abort()
          request = null
        }
        request = xhr
        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )
  }, 500)

  if (choices.input) {
    choices.input.element.addEventListener(`keyup`, (e) => {
      const value = choices.input.element.value.trim()
      if (value != prevValue) {
        prevValue = value
        setNoResultsMsg(`Laddar…`)
        onSearch(value)
      }
    })
  }

  // element.addEventListener(`change`, (e) => {
  //   const { value } = e.detail
  //   const item = choices._currentState.choices.find((i) => i.value == value)
  //   if (item && !defaultValues.find((i) => i.value == value)) {
  //     defaultValues.push(item)
  //   }

  //   if (element.multiple) {
  //     const reducedValues = defaultValues.filter(
  //       (i) => !choices.getValue(true).includes(i.value)
  //     )
  //     restoreValues(reducedValues)
  //   } else {
  //     restoreValues()
  //     choices.setChoiceByValue(value)
  //   }
  // })
}

const createAll = () => {
  create(`.js--choices`, options)

  create(`.js--choices-no-blank`, {
    ...options,
    removeItems: false,
    removeItemButton: false,
  })

  create(`.js--choices-no-duplicate`, {
    ...options,
    duplicateItemsAllowed: false,
  })
}

init(() => {
  instances = []
  createAll()
})

export { destroy, createAll }
