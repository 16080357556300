import { delegateEventListener } from "@oddcamp/js-utils/src/event"

import { centerScrollInParent } from "../utils/pos"

delegateEventListener(
  `.tabbed-content__tabs button`,
  `click`,
  (e, triggerEl) => {
    const triggerLiEl = triggerEl.closest(`li`)
    const tabsEl = triggerLiEl.closest(`.tabbed-content__tabs`)
    const componentEl = tabsEl.closest(`.tabbed-content`)
    const { index } = triggerEl.dataset

    // tabs

    tabsEl.querySelector(`li.\\--active`).classList.remove(`--active`)
    triggerLiEl.classList.add(`--active`)
    centerScrollInParent(tabsEl, triggerEl)

    // content

    componentEl
      .querySelector(`.tabbed-content__content__item.\\--active`)
      .classList.remove(`--active`)

    componentEl
      .querySelector(`.tabbed-content__content__item[data-index="${index}"]`)
      .classList.add(`--active`)
  }
)
